import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { SEO } from "../components/seo"

const sliderSettings = {
  dots: false,
  infinite: true,
  autoplay: true,
	arrows: true,
}

const RestoranPage = () => {
  return (
    <Layout
      backArrow={true}
      bodyClasses={" flat-menu-page -white-third-bg meny-right "}
    >
      <Breadcrumbs currentPage="Restoran" />
      <main className="main-content">
        <div className="container">
          <h1 className="title-of-page center-aligned">Restoran</h1>

					<section className="company-info-section -bottom-indent">
						<div className="row -bottom-indent">
							<div className="col-12 col-lg-12 about__company-content center-aligned">
								<h2 className="title-section title-accent">Novi ukusi na starom Dorćolu</h2>

								<div className="text-block -no-margin">
                  <p>Barrel House je kuća dobrih ukusa, koja se prostire na čak 500 kvadratnih metara.</p>
                  <p>Bilo da ste ljubitelj nacionalne kuhinje ili više volite spoj različitih svetskih trendova…</p>
                  <p>Ovde ćete pronaći svoju čašicu ukusa!</p>
                  <p>Za posebne trenutke koje želite da podelite sa odabranim ljudima, bilo da je u pitanju rođendan, poslovni događaj, godišnjica ili venčanje, Barrel House je prava mera zadovoljstva.</p>
                  <p>Rezervište vaš datum, sve ostalo prepustite nama!</p>
                  <p>Telefon za rezervacije: <a href="tel:00381604466039">060/44-66-039</a></p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-lg-12">
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '2rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1010-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1038-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1058-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1084-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1122-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1210-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1332-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1359(1)-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1387-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/restoran-slajder/043A1419-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>
							</div>
						</div>
					</section>

        </div>
      </main>
    </Layout>
  )
}

export default RestoranPage

export const Head = () => (
  <SEO />
)
